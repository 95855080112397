<template>
  <div class="recruitment">
    <div>
      <div
        style="
          position: relative;
          width: 100%;
          background: #0084f8;
          height: 55px;
        "
        @click="search = true"
      >
        <div class="search">
          <van-icon name="search" />
          <span style="margin-left: 15px">请选择搜索内容</span>
        </div>
      </div>
      <van-popup v-model="search" position="top" :style="{ height: '42%' }">
      <div style="margin-top: 20px;">
        <van-field
          v-model="listdata.title"
          label="职位名称"
          placeholder="请输入职位名称"
        />
        <van-field
          v-model="listdata.eName"
          label="企业名称"
          placeholder="请输入企业名称"
        />
        <!-- <van-field
          v-model="listdata.abiName"
          label="职业类型"
          placeholder="请选择职业类型"
          @click="abiName = true"
          readonly="readonly"
        />
        <van-popup v-model="abiName" position="bottom" get-container="body">
          <van-cascader
            title="请选择职业类型"
            :options="zhiye"
            @close="abiName = false"
            :field-names="{
              text: 'Name',
              value: 'Coding',
              children: 'Children',
            }"
            @finish="onabiName"
          />
        </van-popup> -->
        <van-field
          v-model="listdata.workAddr"
          label="工作地址"
          placeholder="请选择工作地址"
        />
        <van-button
          round
          type="info"
          style="width: 90%; margin: 25px 5% 10px"
          @click="toliost()"
          >搜 索</van-button
        >
        <van-button
          round
          type="primary"
          style="width: 90%; margin: 5px 5%"
          @click="serachReset"
          >重 置</van-button
        >
        </div>
      </van-popup>
    </div>
    <div>
      <div style="margin-bottom: 60px">
        <!-- 轮播图 -->
        <div>
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <!-- <van-swipe-item>
              <img src="../../assets/lunboa.jpg" alt="" style="width: 100%" />
            </van-swipe-item> -->
            <van-swipe-item>
              <img src="../../assets/lunbob.jpg" alt="" style="width: 100%" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 登录入口 -->
        <div style="width: 95%; margin: 8px 2.5%">
          <span class="loginbox">
            <div style="margin-right: 4%" @click="firmlogin">
              <img
                style="width: 25%; display: inline-block; margin: 13px"
                src="../../assets/img_company@2x.png"
                alt=""
              />
              <span class="loginbox_A">
                <span style="display: inline-block; margin-left: 8px"
                  >企业登录</span
                >
                <span
                  style="
                    display: inline-block;
                    margin-left: 12px;
                    font-size: 12px;
                    margin-top: 10px;
                  "
                  >我要招人</span
                >
              </span>
            </div>
          </span>
          <span class="loginbox">
            <div @click="recruitment">
              <img
                style="width: 25%; display: inline-block; margin: 13px"
                src="../../assets/img_personal@2x.png"
                alt=""
              />
              <span class="loginbox_A">
                <span style="display: inline-block; margin-left: 5px"
                  >求职者登录</span
                >
                <span
                  style="
                    display: inline-block;
                    margin-left: 12px;
                    font-size: 12px;
                    margin-top: 10px;
                  "
                  >我要找工作</span
                >
              </span>
            </div>
          </span>
        </div>
        <!-- 广播通告 -->
        <div class="box">
          <van-notice-bar
            left-icon="volume-o"
            text="【友情提示】 智慧就业已正式发布使用!"
          />
        </div>
        <!-- 热门公司 -->
        <div class="box">
          <div
            style="
              padding: 10px 0px;
              position: relative;
              font-size: 18px;
              width: 100%;
            "
          >
            <span class="dot"></span>
            <span>热门公司</span>
            <span class="title_right" @click="toliost(null)">
              更多
              <van-icon name="arrow" class="icon_right" />
            </span>
          </div>
          <van-grid
            class="gongsi"
            style="padding-bottom: 20px"
            :border="false"
            :gutter="10"
            :column-num="2"
          >
            <van-grid-item class="blue" style="">
              <div style="font-size: 14px">河南品讯网络科技有限公司</div>
              <div style="font-size: 12px; margin-top: 5px">
                <span>互联网 </span> <span> 60-100人</span>
              </div>
            </van-grid-item>
            <van-grid-item class="green">
              <div style="font-size: 14px">河南万川智能科技有限公司</div>
              <div style="font-size: 12px; margin-top: 5px">
                <span>市场/销售 </span> <span> 10-20人</span>
              </div>
            </van-grid-item>
            <van-grid-item class="red">
              <div style="font-size: 14px">洛阳希数大数据科技有限公司</div>
              <div style="font-size: 12px; margin-top: 5px">
                <span>互联网 </span> <span> 40-60人</span>
              </div>
            </van-grid-item>
            <van-grid-item class="orange">
              <div style="font-size: 14px">河南得贤人力资源服务有限公司</div>
              <div style="font-size: 12px; margin-top: 5px">
                <span>人事 </span> <span> 100-200人</span>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
        <!-- 热门行业 -->
        <div class="box">
          <div
            style="
              padding: 10px 0px;
              position: relative;
              font-size: 18px;
              width: 100%;
            "
          >
            <span class="dot"></span>
            <span>热门行业</span>
            <span class="title_right" @click="toliost(null)"
              >更多

              <van-icon name="arrow" class="icon_right" />
            </span>
          </div>
          <van-swipe
            class="my-swipe"
            :autoplay="300000"
            indicator-color="white"
            loop="false"
          >
            <van-swipe-item class="gridlist">
              <van-grid style="padding-bottom: 20px" :border="false">
                <van-grid-item @click="toliost(1)">
                  <img src="../../assets/icon_internet@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">互联网</div>
                </van-grid-item>
                <van-grid-item @click="toliost(2)">
                  <img src="../../assets/icon_finance@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">金融</div>
                </van-grid-item>

                <van-grid-item @click="toliost(3)">
                  <img src="../../assets/icon_car@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">贸易物流</div>
                </van-grid-item>
                <van-grid-item @click="toliost(4)">
                  <img src="../../assets/icon_educate@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">教育传媒</div>
                </van-grid-item>
                <van-grid-item @click="toliost(5)">
                  <img src="../../assets/icon_media@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">服务业</div>
                </van-grid-item>
                <van-grid-item @click="toliost(6)">
                  <img
                    src="../../assets/icon_Medical_treatment@2x.png"
                    alt=""
                  />
                  <div style="padding-top: 12px; font-size: 12px">市场销售</div>
                </van-grid-item>
                <van-grid-item @click="toliost(7)">
                  <img src="../../assets/icon_traffic@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">人事财务</div>
                </van-grid-item>
                <van-grid-item @click="toliost(8)">
                  <img src="../../assets/icon_estate@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 12px">房地产</div>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
            <!-- <van-swipe-item class="gridlist">
              <van-grid style="padding-bottom: 20px" :border="false">
                <van-grid-item @click="toliost(null)">
                  <img src="../../assets/icon_internet@2x.png" alt="" />
                  <div style="padding-top: 12px; font-size: 14px">其他</div>
                </van-grid-item>
  
              </van-grid>
            </van-swipe-item> -->
          </van-swipe>
        </div>
      </div>
    </div>
    <!-- <van-tabbar v-model="active">
      <van-tabbar-item icon="manager-o" to="/list/firmlogin/"
        >企业端登录</van-tabbar-item
      >
      <van-tabbar-item icon="wap-home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/list/recruitment/"
        >求职者登录</van-tabbar-item
      >
    </van-tabbar> -->
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import wx from "weixin-js-sdk";
import { getToken, getaccToken } from "@/utils/auth";
import { WeGetJobPage } from "@/api/ResumeJS";
import { GetAbilityList } from "@/api/REplat";
export default {
  data() {
    return {
      itemmark: "", //职位详情
      listdata: {
        accToken: getToken(), //token
        title: "", //名称
        abiCode: "", //职业编码
        abiName: "", //职业名称
        workAddr: "", //地址
        eName: "", //企业
        limit: 10,
        page: 1,
      },
      conterlist: [
        { value: 1, Name: "互联网", url: "../../assets/icon_internet@2x.png" },
        { value: 2, Name: "金融", url: "../../assets/icon_finance@2x.png" },
        { value: 3, Name: "房地产", url: "../../assets/icon_estate@2x.png" },
        { value: 4, Name: "汽车", url: "../../assets/icon_car@2x.png" },
        { value: 5, Name: "教育培训", url: "../../assets/icon_educate@2x.png" },
        { value: 6, Name: "广告传媒", url: "../../assets/icon_media@2x.png" },
        {
          value: 7,
          Name: "医疗制药",
          url: "../../assets/icon_Medical_treatment@2x.png",
        },
        { value: 8, Name: "交通运输", url: "../../assets/icon_traffic@2x.png" },
      ],
      active: 0,
      list: [], //列表
      count: 0,
      zhiye: [],
      NativeCantonCodedata: [],
      search: false, //搜索框显示
      abiName: false,
      hidden: false,
      routers: {},
    };
  },
  created() {
    this.GetJobPage();
    this.zidianlist();
    console.log(getToken());
  },
  methods: {
    firmlogin() {
      if (getaccToken() == undefined) {
        this.$router.push({
          path: "/list/firmlogin/",
        });
      } else {
        this.$router.push({
          path: "/list/company",
        });
      }
    },
    recruitment() {
      if (getToken() == undefined) {
        this.$router.push({
          path: "/list/recruitment/",
        });
      } else {
        this.$router.push({
          path: "/list/jobhunter",
        });
      }
    },
    toliost(e) {
      var canshu = {
        title: this.listdata.title,
        eName: this.listdata.eName,
        workAddr: this.listdata.workAddr,
      };
      if (e == null) {
        canshu.abiCode = e;
      } else {
        canshu.abiCode = "0" + e;
      }
      this.routers = JSON.stringify(canshu);
      this.$router.push({
        path: "/list/positionlist/" + this.routers,
      });
    },
    pageclick(e) {
      this.listdata.page = e;
      this.GetJobPage();
    },
    onSearch() {
      this.listdata.page = 1;
      this.GetJobPage();
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    serachGo() {
      this.GetJobPage();
      this.search = false;
    },
    serachReset() {
      this.listdata = {
        accToken: getToken(), //token
        title: "", //名称
        abiCode: "", //职业编码
        abiName: "", //职业名称
        workAddr: "", //地址
        eName: "", //企业
      };
      this.GetJobPage();
      this.search = false;
    },
    // 获取职业列表
    GetJobPage() {
      WeGetJobPage(this.listdata).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          if (res.data.data.length == 0) {
            this.hidden = true;
            this.list = [];
          } else {
            this.hidden = false;
            this.list = res.data.data;
            this.count = res.data.count;
          }
        } else {
          this.hidden = true;
          Toast.fail("获取失败!" + res.data.msg);
        }
      });
    },
    // 跳转职位详情
    todetail(row) {
      this.itemmark = JSON.stringify(row);
      this.$router.push({
        name: "detail",
        query: { JId: this.itemmark },
      });
    },
    // 字典
    zidianlist: function () {
      GetAbilityList().then((res) => {
        this.zhiye = res.data.data;
      });
    },
    // 行业选中
    onabiName: function (val) {
      console.log(val);
      if (val.selectedOptions.length == 1) {
        this.listdata.abiCode = val.selectedOptions[0].Coding;
        this.listdata.abiName = val.selectedOptions[0].Name;
      } else {
        this.listdata.abiCode = val.selectedOptions[1].Coding;
        this.listdata.abiName = val.selectedOptions[1].Name;
      }
      this.abiName = false;
    },
  },
};
</script>
<style>
.recruitment {
  background: #eceff0;
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.recruitment .van-nav-bar__title {
  color: #fff;
}
.recruitment .van-nav-bar__content {
  background: #0188fb;
}
.postlist {
  margin: 5px 0px;
  background: #fff;
}
.postlist .listtitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 700;
  color: #000;
}
.postlist .listtwo {
  padding: 0px 10px;
  font-size: 12px;
}
.postlist .listtwo span {
  padding: 6px;
  background: rgb(238, 237, 237);
  margin-right: 8px;
  color: #666;
}
.postlist .listthree {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 14px;
}
.postlist .listthree span {
  margin-right: 30px;
  color: #666;
}
.postlist .listfore {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0px 0px 15px 10px;
  font-size: 13px;
}
.postlist .listfore span {
  margin-right: 15px;
  color: #a3a7a8;
}
.van-search__action {
  margin-right: 10px !important;
  color: #fff;
}
.van-search__action:active {
  background: #0188fb;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.nameResumes {
  position: fixed;
  right: 100px;
  bottom: 20px;
  background: #0188fb;
  text-align: center;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.search {
  display: flex;
  align-items: center;
  position: absolute;
  background: rgb(255, 255, 255);
  left: 5%;
  top: 10px;
  width: 85%;
  height: 35px;
  padding-left: 15px;
  font-size: 14px;
  border-radius: 15px;
  color: #999999;
}
.gridlist img {
  width: 60%;
}
.box {
  width: 95%;
  margin: 10px 2.5%;
  background: #fff;
  border-radius: 8px;
}
.box .van-notice-bar {
  border-radius: 8px;
  background: #fff;
}
.dot {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 3px;
  background: #ff9d30;
  margin: 0px 10px 0px 10px;
}
.title_right {
  color: #7d7d7d;
  position: absolute;
  right: 15px;
  font-size: 14px;
}
.icon_right {
  position: relative;
  right: 0px;
  top: 2px;
}
.loginbox div {
  position: relative;
  display: inline-block;
  width: 48%;
  background: #fff;
  height: 75px;
  color: #000;
  /* font-size: 22px; */
  border-radius: 8px;
}
.loginbox_A {
  position: absolute;
  top: 15px;
  display: inline-block;
  width: 60%;
}
.blue .van-grid-item__content {
  color: #fff;
  /* background:#4a8bfe; */
  background: url("../../assets/img_company_bj1@2x.png") no-repeat center center;
}
.green .van-grid-item__content {
  color: #fff;
  background: url("../../assets/img_company_bj2@2x.png") no-repeat center center;
}
.red .van-grid-item__content {
  color: #fff;
  background: url("../../assets/img_company_bj3@2x.png") no-repeat center center;
}
.orange .van-grid-item__content {
  color: #fff;
  background: url("../../assets/img_company_bj4@2x.png") no-repeat center center;
}
.gongsi .van-grid-item__content--center {
  -webkit-align-items: end;
  align-items: end;
}
.gongsi .van-grid-item__content {
  padding: 4px 8px;
  background-size: 100%;
  border-radius: 8px;
}
</style>